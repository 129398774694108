
export default {
  props: {
    blok: {
      type: Object,
      default: () => {},
    },
    target: {
      type: String,
      default: null,
    },
  },
}
